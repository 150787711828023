import React from 'react'
import { Link } from 'gatsby'
import { Location } from "@reach/router"

import Layout from '../../components/layout'
import SEO from '../../components/seo'
import MarTech from "../../components/MarTech";

function Startups(props) {
  const steps = [
    {
      step: 0,
      title: 'Mindset',
      image: 'mindset.svg',
      lines: [
        `The most important thing to make your dream a reality is to believe you can do it.`,
        `Then commit to do it.`,
        `Then persevere. Just keep doing it until you are successful.`
      ],
      recommended: [
        // { title: `Whether you think you can or you can't, you're right!`, link: '#' },
        // { title: `Don't wait to start your company`, link: '#' },
        // { title: `Why NOT being technical might help you as the founder of a software company`, link: '#' },
      ]
    },
    {
      step: 1,
      title: 'Ideation',
      image: 'ideation.svg',
      lines: [
        `The best advice for what business idea to pursue is self reflection.`,
        `Your professional background, skills, and interests should be the primary driver for this important decision.`,
      ],
      recommended: [
        // { title: `How To Choose What Your App Should Be`, link: '#' },
        // { title: `Put your years of experience to good use`, link: '#' },
        // { title: `You are exactly the right person do develop the app only you can`, link: '#' },
      ]
    },
    {
      step: 2,
      title: 'Market Research',
      image: 'market-research.svg',
      lines: [
        `During this step we define your customer avatar and learn where people like that can be found.`
      ],
      recommended: [
        // { title: `How to connect with your audience`, link: '#' },
        // { title: `Create realistic customer avatars for your app startup`, link: '#' },
      ]
    },
    {
      step: 3,
      title: 'Startup Validation',
      image: 'startup-validation.svg',
      lines: [
        `This is the part everyone wants to skip, and it's the biggest factor that determines if your business will be among the 90% of startups that fail.`,
        `Make sure you are building something people want to buy`
      ],
      recommended: [
        // { title: `It's all about "Validated Learning"`, link: '#' },
        // { title: `What you should learn from the Lean Startup`, link: '#' },
      ]
    },
    {
      step: 4,
      title: 'Product Design',
      image: 'product-design.svg',
      lines: [
        `Here's where the real fun begins. Designing the user flows and screens in your app`,
        `This allows us to get user feedback early, and know exactly what to build.`
      ],
      recommended: [
        // { title: `How to sketch your app`, link: '#' },
        // { title: `All about user experience (UX)`, link: '#' },
        // { title: `Figure out your user flows`, link: '#' },
      ]
    },
    {
      step: 5,
      title: 'MVP Development',
      image: 'mvp-development.svg',
      lines: [
        `Your MVP is the minimal version of your app that you can take to market and provide value for your users.`,
        `Plan to add more features after you launch and get feedback.`
      ],
      recommended: [
        // { title: `Estimating how long development will take`, link: '#' },
        // { title: `What is an MVP and how is it different than a fully developed product?`, link: '#' },
      ]
    },
    {
      step: 6,
      title: 'Launch!',
      image: 'launch.svg',
      lines: [
        `You did it!  Your app is live and you're on-boarding users as fast as you can.`,
        `Pay attention to usage and metrics. Talk to your users.`
      ],
      recommended: [
        // { title: `Getting your first users onboarded and learning from them`, link: '#' },
        // { title: `What to measure and which metrics to pay attention to`, link: '#' },
      ]
    },
    {
      step: 7,
      title: 'Ongoing Operations',
      image: 'ongoing-operations.svg',
      lines: [
        `You've been at the helm of your software business for a while and have most things under control.`,
        `Stay close to your users, plan your feature roadmap and develop what your customers ask for.`
      ],
      recommended: [
        // { title: `Supporting your customers`, link: '#' },
        // { title: `Planning new features, and new releases of your software`, link: '#' },
      ]
    },
  ]

  return (
    <Layout>
      <Location>
        {({ location }) =>
          <SEO
            title="For Startups"
            pathname={location.pathname}
            keywords={[`gortek`, `app`, `startup`, `business`]}
          />
        }
      </Location>

      <MarTech />

      <h2 className="text-primary">For Startups</h2>
      <p>We help you on your Startup Journey to keep you on your path to success.</p>

      <div className="h-16" />
      {steps.map((step, index) => (
        <div key={index}>
          <div className="bg-white relative rounded shadow-xl p-6">
            <div
              className="bg-secondary text-white text-xs absolute rounded-full shadow-md p-3 h-16 w-16 flex text-center justify-center items-center"
              style={{ top: '-1.5rem' }}
            >
              Step {step.step}
            </div>
            <h5 className="text-secondary font-bold md:pl-20 md:text-left text-center md:mt-0 mt-5">
              {step.title}
            </h5>
            <div className="flex md:pl-20 md:flex-row flex-col pl-0">
              <div className="md:w-1/2 p-8">
                {step.image
                  ? <img alt={step.title} src={require(`../../images/startup-app-process/${step.image}`)} />
                  : null
                }
              </div>
              <div className="md:w-1/2 text-gray-600">
                {step.lines.map((line, index) => (
                  <div key={index}>
                    <p>
                      {line}
                    </p>
                    <br />
                  </div>
                ))}
              </div>
            </div>
            {step.recommended.length ? (
              <>
                <div
                  className="md:ml-20 -mr-6 my-3 bg-black"
                  style={{
                    height: 3,
                    backgroundImage: `linear-gradient(to right, white, gray)`
                  }}
                />
                <div className="md:ml-20">
                  <p className="font-bold text-blue-600">
                    Recommended Reading
                  </p>
                  {step.recommended.map((item, index) => (
                    <Link
                      key={index}
                      to={item.link}
                      className="text-sm block"
                    >
                      ▶ {item.title}
                    </Link>
                  ))}
                </div>
              </>
            ) : null}
          </div>
          {index !== steps.length - 1
            ? <div className="border-secondary border-l-4 h-20" style={{ marginLeft: 'calc(3.5rem - 2px)' }} />
            : null
          }
        </div>
      ))}
    </Layout>
  )
}

export default Startups
